<template>
    <div>
        <table :style='{"width":"100%", "font-size":"7pt", "border-collapse": "collapse", "line-height": "normal"}'>
            <thead>
                <tr :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400"}}'>
                    <td
                        width="10%"
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Hora
                    </td>

                    <td
                        width="40%"
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Tipo de dieta
                    </td>
                    <td
                        width="10%"
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Medio
                    </td>
                    <td
                        width="20%"
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Indicado Tolerado
                    </td>
                    <td
                        width="20%"
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Per. pre/post prandial
                    </td>
                </tr>
            </thead>
            <tbody :style='{"vertical-align":"top", "white-space": "pre-wrap"}' v-for="(itm, id) in datos.nutricion" :key="id">
                <tr>
                    <td :style='{...estilos.tablaBorde, ...{"text-align":"center"}}'>{{itm.hora}}</td>
                    <td :style='estilos.tablaBorde'>{{itm.tipoDieta}}</td>
                    <td :style='{...estilos.tablaBorde, ...{"text-align":"center"}}'>{{itm.medio}}</td>
                    <td :style='{...estilos.tablaBorde, ...{"text-align":"center"}}'>{{itm.cantidadIndicada}}ml / {{itm.tolera}}ml</td>
                    <td :style='{...estilos.tablaBorde, ...{"text-align":"center"}}'>{{itm.prePrandial}} / {{itm.postPrandial}}</td>
                </tr>
                <tr v-if="itm.observaciones">
                    <td colspan="5" :style='estilos.tablaBorde'>
                        {{itm.observaciones}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],

}
</script>

<style>

</style>